import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[csNavLink]',
  standalone: true
})
export class NavLinkDirective implements OnInit {
  constructor(private el: ElementRef) { }

  ngOnInit() {
    const btnClasses = 'inline-block rounded-full px-2 py-1 text-md text-slate-200 transition-colors border border-transparent hover:border-pink-300 hover:text-pink-300';
    this.el.nativeElement.classList.add(...btnClasses.split(' '));
  }

}
