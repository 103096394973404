import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ContainerComponent } from '../container/container.component';
import { LogoComponent } from '../logo/logo.component';
import { NavLinkDirective } from '../../directives/nav-link.directive';
import { ScrollToDirective } from '../../directives/scroll-to.directive';

@Component({
  selector: 'cs-footer',
  standalone: true,
  imports: [RouterLink, ContainerComponent, LogoComponent, NavLinkDirective, ScrollToDirective],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  year = new Date().getFullYear();
}
